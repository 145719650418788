.image-gallery {
  width: 100% !important;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
}

.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
  height: 40px !important;
  width: 28px !important;
}

.thumbnail-gallery {
  width: 100% !important;
  height: 60px;
  
}

.image-gallery-thumbnail.thumbnail-gallery {
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.image-gallery-thumbnail.thumbnail-gallery.active {
  border: 2px solid rgba(0, 0, 0, 0.397);
}

.image-gallery-thumbnail.thumbnail-gallery:hover {
  border: 2px solid rgba(0, 0, 0, 0.397);
}

.image-gallery-thumbnail-inner {
  height: 100%;
}

.image-gallery-thumbnail-image {
  height: 100%;
  object-fit: cover;
}

.image-gallery {
  height: 100%;
}

.image-gallery-content {
  height: 100%;
}

.image-gallery-slide-wrapper {
  height: 100%;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.image-gallery-slides {
  height: 100%;
}

.image-gallery-slide {
  height: 100%;
}

.image-gallery-swipe {
  height: 100%;
}

.image-gallery-slide .image-gallery-counter {
  top: 10px;
  right: 10px;
  padding: 4px 12px;
  background-color: #00000080;
  border-radius: 16px;
  position: absolute;
  color: #FFFFFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.17px; 
}

.image-gallery-slide .image-gallery-description {
  bottom: 10px;
  left: 10px;
  width: 80% !important;
  /* display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column; */
  border-radius: 16px;
}

.image-gallery-slide .image-gallery-description .title {
  font-size: 14px;
  font-weight: 500;
  /* margin-bottom: 10px; */
  text-align: left;
}

.image-gallery-slide .image-gallery-description .description {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.image-gallery-image {
  height: 100%;
  /* object-fit: cover !important; */
  border-radius: 16px;
}

.image-gallery-content.fullscreen .image-gallery-image {
  object-fit: contain !important;
}