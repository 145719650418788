.action-button.MuiButtonBase-root {
  color: #000000;
  text-transform: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.4px; 
  /* padding: 16px; */
}

.action-button .MuiSvgIcon-root {
  color: black;
}