.preview {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 16px;
}

.preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}