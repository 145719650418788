.back-button {
  text-transform: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px; 
  height: 100%;
  white-space: nowrap;
  list-style: 0;
  color: #000000DE;
}

.back-button .MuiSvgIcon-root {
  color: black;
}