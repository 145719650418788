.tree-select {
	width: 100%;
}

.tree-select.ant-select {
	height: 40px;
}

.tree-select.ant-select .ant-select-selector {
	border-radius: 4px;
	font-size: 1rem;
}

.tree-select.ant-select .ant-select-clear {
	width: unset;
	height: unset;
	margin-top: -12px;
	margin-right: 6px;
}

.tree-select.ant-select .ant-select-selector .ant-select-selection-placeholder {
	color: rgba(0, 0, 0, 0.6);
}