.button {
  text-transform: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px; 
}

.submitFormButton.MuiButtonBase-root {
  border-color: #5B90E1;
  background-color: #5B90E1;
  color: #FFFFFF;
  transition: 250ms;
}

.submitFormButton.MuiButtonBase-root:hover {
  border-color: #5B90E1;
  background-color: #5B90E1;
  color: #FFFFFF;
  opacity: 0.7;
}

.resetFormButton.MuiButtonBase-root {
  border-color: #F5F5F5;
  background-color: #F5F5F5;
  color: #000000;
}