.btn-filter-icon path {
  fill: #000000;
}

.btn-filter-icon-active path {
  fill: #000000;
}

.react-grid-item {
  cursor: grab;
}

.react-grid-item.react-draggable-dragging {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 16px;
  cursor: grabbing;
}

.react-grid-item.react-grid-placeholder {
  background-color: #9cafc7 !important;
}