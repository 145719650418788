@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: #BDBDBD #EEEEEE

}
*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
*::-webkit-scrollbar-track {
  background: #EEEEEE;
}
*::-webkit-scrollbar-thumb {
  background-color: #BDBDBD;
}

body {
  width: 100%;
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
}
