.popover-form {
  /* padding: 24px 16px;
  width: 600px; */
}

@media (max-height: 780px) {
  .popover-form  {
    max-height: 600px;
  }  
}
