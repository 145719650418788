.modal-button.MuiButtonBase-root {
  /* color: #000000; */
  text-transform: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.4px; 
  padding: 16px;
}

.modal-button.MuiButtonBase-root.MuiButton-contained {
  background-color: #5B90E1;
  text-transform: uppercase;
  border-radius: 8px;
  font-size: 15px;
}

.modal-button.MuiButtonBase-root.MuiIconButton-root {
  padding: 4px
}