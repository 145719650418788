.heading.MuiTypography-h1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px; 
}

.heading.MuiTypography-h2 {
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px; 
}

.heading.MuiTypography-h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px; 
}

.heading.MuiTypography-h4 {
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px; 
}

.heading.MuiTypography-h5 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px; 
}

.heading.MuiTypography-h6 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px; 
  padding: 0 8px;
}