.page-error .logo {
    width: 240px;
}

.page-error .container {
    width: 600px;
    height: 350px;
    border: 5px solid #4D76AD;
    border-radius: 8px;
}

.page-error .text {
    width: 700px;
    font-size: 30px;
}

.page-error .button {
    width: max-content;
}

@media (max-height: 830px) {
    .page-error .MuiStack-root > :not(style) ~ :not(style) {
        margin-top: 32px;
    }

    .page-error .logo {
        width: 200px;
    }

    .page-error .container {
        width: 500px;
        height: 350px;
        border: 5px solid #4D76AD;
        border-radius: 8px;
    }

    .page-error .text {
        width: 700px;
        font-size: 22px;
    }

    /* .button {
        width: 300px;
    } */

    .page-error .button button{
        padding: 10px 20px;
    }

  }
  