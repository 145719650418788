.popover-button.MuiButtonBase-root {
  color: #000000;
  text-transform: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.4px; 
  padding: 16px;
}

.popover-button.MuiButtonBase-root.MuiIconButton-root {
  padding: 4px
}